import { COOKIES, getCookie } from "utils/cookies";
import { checkDevicePlatform, isFromNativeApp } from "utils/misc";
import dynamic from "next/dynamic";
import { useCallback } from "react";
import { PLATFORM_TYPE } from "constants/store";

const UpdateScreen = dynamic(() => import('./UpdateScreen'));

function AppForceUpdate() {
  const nativeAppVersion = getCookie(null, COOKIES.nativeAppVersion) || '';

  const compareVersion = useCallback((version1: string, version2: string) => {
    const v1 = parseInt(version1);
    const v2 = parseInt(version2);
    
    if (v1 > v2) return 1;
    if (v1 < v2) return -1;

    return 0;
  }, []);

  const compareVersionByPlatform = useCallback(() => {
    if (checkDevicePlatform(PLATFORM_TYPE.IOS)) return compareVersion(nativeAppVersion, "19");
    if (checkDevicePlatform(PLATFORM_TYPE.Android)) return compareVersion(nativeAppVersion, "6");

    return 1;
  }, [nativeAppVersion]);
  
  if (isFromNativeApp() && nativeAppVersion && compareVersionByPlatform() < 0) {
    return <UpdateScreen />;
  }

  return <></>;
}

export default AppForceUpdate;